import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import router from "@/router/clean";



export interface DashboardIndicators{
  encAbertas: number;
  incAbertas: number;
  incFechadas: number;
  incMonth: number;
  fat12Meses: number;
  fatAnoCorrente: number;
  porRegularizar: number,
  taxaResposta: number,
  inDebtVencidos: number,
  salesMan: {
    name: string,
    email: number,
    phone: number,
  }
}

export interface DashboardInfo {
  errors: unknown;
  dashboardInvoices: DashboardIndicators;

}

@Module
export default class DashboardModule extends VuexModule implements DashboardInfo {
  errors : unknown;
  dashboardInvoices = {} as DashboardIndicators;


  get getDashboardInvoices(): DashboardIndicators {
    return this.dashboardInvoices;
  }

  @Mutation
  [Mutations.SET_DASH_INVOICES](dashboardInvoices: any) {
    //map fields
    this.dashboardInvoices.encAbertas = dashboardInvoices.openOrders;
    this.dashboardInvoices.incAbertas = dashboardInvoices.openOccurrences;
    this.dashboardInvoices.incFechadas = dashboardInvoices.closedOccurrences;
    this.dashboardInvoices.incMonth = dashboardInvoices.monthOccurrences;
    this.dashboardInvoices.fat12Meses = dashboardInvoices.last12MonthsRevenue;
    this.dashboardInvoices.fatAnoCorrente = dashboardInvoices.totalYearSales;
    this.dashboardInvoices.porRegularizar = dashboardInvoices.inDebt;
    this.dashboardInvoices.taxaResposta = dashboardInvoices.responseRatio;
    this.dashboardInvoices.inDebtVencidos = dashboardInvoices.inDebtVencidos;
    this.dashboardInvoices.salesMan = dashboardInvoices.salesMan;

  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }


  @Action
  [Actions.REFRESH]() {
    //load stored cart
    this.context.dispatch(Actions.LOAD_CART_FROM_STORAGE);

    //load user
    this.context.dispatch(Actions.RESTORE_TOKEN)
    
  }


  @Action
  [Actions.LOAD_DASH_INVOICES](user: any) {

    ApiService.setHeader();
      ApiService.get("/Utils/indicadores/" + user)
        .then(({ data }) => {
          if(data.Data)
            this.context.commit(Mutations.SET_DASH_INVOICES, data.Data)
        })
        .catch(({ data }) => {
          console.log(data)
        });
        
  }


  
}
