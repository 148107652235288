import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/faturas",
        name: "faturas",
        component: () => import("@/views/Invoices/Invoices.vue"),
      },
      {
        path: "/encomendas",
        name: "encomendas",
        component: () => import("@/views/Orders/Orders.vue"),
      },
      {
        path: "/incidencias",
        name: "incidencias",
        component: () => import("@/views/occurrences/Occurrences.vue"),
      },
      {
        path: "/occurence/:tipo",
        name: "occurence",
        props : true,
        component: () => import("@/views/occurrences/Occurence.vue"),
      },
      {
        path: "/resolucao/:incNmr",
        name: "resolucao",
        props: true,
        component: () => import("@/views/occurrences/Conclusion.vue"),
      },
      {
        path: "/perfil",
        name: "perfil",
        component: () => import("@/views/user/changeProfil.vue"),
      },
      {
        path: "/neworder",
        name: "newOrder",
        component: () => import("@/components/Orders/ImportCsvAndConvertInOrder.vue"),
      },
      {
        path: "/products",
        name: "products",
        component: () => import("@/views/Products/Products.vue"),
      },
      {
        path: "products/manuals",
        name: "ProductsManuals",
        component: () => import("@/views/Products/ProductsManuals.vue"),
      },
      {
        path: "/clientProducts",
        name: "clientProducts",
        component: () => import("@/views/Products/ClientProducts.vue"),
      },

        {
        path: "userDocs",
        name: "userDocs",
        component: () => import("@/views/ClientDocuments/ClientDocuments.vue"),
        }
    ],
  },
  
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },


      
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  {
    path: "/confirm-password/:token",
    name: "confirm-password",
    meta: {requiresGuest: true },
    props : true,        
    component: () =>
      import("@/views/crafted/authentication/basic-flow/ConfirmPassword.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from) => {
  // reset config to initial state
  //if no token and not in sign-in/recover/confirm page
  if (!JwtService.getToken() && to.name !== "sign-in" && to.name !== "password-reset" && to.name !== "confirm-password") {
    router.push("/sign-in");
  }

  else if(Number(store.getters.currentAuthUser.expires * 1000) < new Date().getTime()){
    store.dispatch(Actions.REFRESH_TOKEN);
  }

  //if !userAuth.areaFinanceira
  else if (!store.getters.currentAuthUser.acessoAreaFinanceira && (to.name === "dashboard")) {
    //dont allow to go further
    //router.push("/incidencias");
    router.push("/encomendas");
  }


  //if !userAuth.areaComercial
  else if (!store.getters.currentAuthUser.acessoAreaComercial && (to.name === "encomendas" || to.name === "incidencias" ||
        to.name === "occurence" || to.name === "resolucao" || to.name === "newOrder" || to.name === "clientProducts" ||
        to.name === "ProductsManuals" || to.name === "products")) {
      router.push("/dashboard");
  }



  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  //Load notifications
  //store.dispatch(Actions.LOAD_NOTIFICATIONS);

  //store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
