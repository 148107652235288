import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import InvoiceModule from "@/store/modules/InvoicesModule";
import ProductModule from "@/store/modules/Products";
import DashboardModule from "@/store/modules/DashboardModule";
import OccurenceModule from "@/store/modules/OccurencesModule";
import InDebtModule from "@/store/modules/InDebtModule";
import NotificationsModule from "@/store/modules/NotificationsModule";
import LastActivitiesModule from "@/store/modules/LastActivitiesModule";
import OrderCartModule from "@/store/modules/OrderCartModule";

config.rawError = true;

const store = createStore({
  state () {
    return {

    }


  },
  modules: {    
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    InvoiceModule,
    ProductModule,
    DashboardModule,
    OccurenceModule,
    InDebtModule,
    NotificationsModule,
    LastActivitiesModule,
    OrderCartModule,
  },
});

export default store;
