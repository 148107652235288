import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";
import {Product} from "@/store/modules/Products";

const CART_KEY_STORAGE = "cart";

export interface Order {
    strNumExterno: string;
    intCodEntidade: string;
    strObs: string;
    Mov_Encomenda_Lin: Array<{
        intNumLinha: number,
        strDescricao: string,
        strCodArtigo: string,
        fltQuantidade: number,
        strAbreviatura: string,
        tipoStock: string,
    }>;
}

export interface ProductInfo {
    errors: unknown;
    order: Order
}

@Module
export default class OrderCartModule extends VuexModule implements ProductInfo {
    errors : unknown;
    order: Order = {} as Order;

    get getOrder(): Order {
        return this.order;
    }




    @Action
    [Actions.LOAD_CART_FROM_STORAGE]() {
        const cart = localStorage.getItem(CART_KEY_STORAGE);
        if (cart) {
            this.context.commit(Mutations.STORE_CART_FROM_STORAGE, JSON.parse(cart));
        }

    }

    @Mutation
    [Mutations.STORE_CART_FROM_STORAGE](order: Order) {
        //console.log("loading order", order);
        this.order = order;
    }


    @Mutation
    [Mutations.SET_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_ORDER_HEADER](header) {
        this.order.strObs = header.strObs;
        this.order.strNumExterno = header.strNumExterno;

        //save the order to the local storage
        localStorage.setItem(CART_KEY_STORAGE, JSON.stringify(this.order));
    }


    @Mutation
    [Mutations.ADD_PRODUCT_TO_CART_NO_MAP](products) {

        //VERIFY if the product is already in the cart
        //iterate over the products
        products.forEach((product: any) => {

            if(!product) return;

            //iterate over the cart
            let found = false;
            //if order.Mov_Encomenda_Lin is undefined
            if (this.order.Mov_Encomenda_Lin === undefined) {
                this.order.Mov_Encomenda_Lin = [];
            }

            this.order.Mov_Encomenda_Lin.forEach((cartProduct) => {
                //if the product is already in the cart
                if (cartProduct.strCodArtigo === product.strCodArtigo) {
                    //increment the quantity of the product
                    found = true;
                    cartProduct.fltQuantidade += product.fltQuantidade;
                }
            });

            //if the product is not in the cart
            if(!found){
                this.order.Mov_Encomenda_Lin.push({
                    //autoincrement the line number
                    intNumLinha: this.order.Mov_Encomenda_Lin.length + 1,
                    strCodArtigo: product.strCodArtigo,
                    strDescricao: product.strDescricao,
                    fltQuantidade: product.fltQuantidade,
                    strAbreviatura: product.strAbreviatura,
                    tipoStock: product.tipoStock,
                });
            }
        });

        localStorage.setItem(CART_KEY_STORAGE, JSON.stringify(this.order));
    }

    @Mutation
    [Mutations.ADD_PRODUCT_TO_CART](...products) {
        //VERIFY if the product is already in the cart
        //iterate over the products
        products.forEach((product: Product) => {
            //iterate over the cart
            let found = false;
                this.order.Mov_Encomenda_Lin.forEach((cartProduct) => {
                //if the product is already in the cart
                if (cartProduct.strCodArtigo === product.ref) {
                    //increment the quantity of the product
                    found = true;
                    cartProduct.fltQuantidade += product.quantity;
                }
            });
            //if the product is not in the cart
            if(!found) {
                this.order.Mov_Encomenda_Lin.push({
                    intNumLinha: this.order.Mov_Encomenda_Lin.length + 1,
                    strDescricao: product.design,
                    strCodArtigo: product.ref,
                    fltQuantidade: product.quantity,
                    strAbreviatura: product.strAbreviatura,
                    tipoStock: product.tipoStock,
                });
            }
        });
        localStorage.setItem(CART_KEY_STORAGE, JSON.stringify(this.order));
    }
    @Mutation
    [Mutations.SET_PRODUCTS_CART](...products) {
        products = products[0];
        this.order.Mov_Encomenda_Lin = products.map((product, index) => ({
            //autoincrement the line number
            intNumLinha: index + 1,
            strCodArtigo: product.refe,
            strDescricao: product.design,
            fltQuantidade: product.qtt,
            strAbreviatura: product.abv,
            tipoStock: product.stk,
        }));
        localStorage.setItem(CART_KEY_STORAGE, JSON.stringify(this.order));
    }

    @Mutation
    [Mutations.CLEAR_CART]() {
        this.order = {} as Order;
        localStorage.removeItem(CART_KEY_STORAGE);
    }

}


