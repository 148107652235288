import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Product {
  ref: string;
  design: string;
  unidade: string;
  udata: string;
  obs: string;
  quantity: number; //used in the cart
  strAbreviatura: string;
  documento: string;
  prodFamilia: Array<{
    ref: string;
    nome: string;
  }>;
  lastload: number;
  ftlVolume: number;
  tipoStock: string;

  // Probably only for this project
  refAndDesign: string;
}

export interface ProductInfo {
  errors: unknown;
  products: Array<Product>;
}

@Module
export default class ProductsModule extends VuexModule implements ProductInfo {
  errors: unknown;
  products: Array<Product> = [];

  get getProducts(): Array<Product> {
    return this.products;
  }

  get getProductDocumentByRef(): (ref: string) => string {
    return (ref: string) => {
      const product = this.products.find((prod) => prod.ref === ref);
      return product ? product.documento : "";
    };
  }

  @Mutation
  [Mutations.SET_PRODUCTS](productsIn) {
    this.products.push(
      ...productsIn.map((product) => ({
        ref: product.strCodigo,
        design: product.strDescricao,
        unidade: product.strAbrevMedVnd,
        udata: product.dtmAlteracao,
        obs: product.strObs,
        strAbreviatura: product.strAbreviatura,
        documento: product.documento,
        ftlVolume: product.ftlVolume,
        tipoStock: product.tipoStock,
        prodFamilia: product.Tbl_Gce_ArtigosFamilias.map((familia) => ({
          ref: familia.strCodFamilia,
          nome: familia.strCodTpNivel,
        })),
      }))
    );
  }

  @Mutation
  [Mutations.SET_PRODUCTS_BY_PAGE]({
    productsIn,
    pageNumber = 0,
    pageSize = 0,
  }) {
    if (pageNumber == 0 && pageSize == 0) {
      /* this.products.push(
      ...productsIn.map((product) => ({
        ref: product.strCodigo,
        design: product.strDescricao,
        strAbreviatura: product.strAbreviatura,
        ftlVolume: product.ftlVolume,
        tipoStock: product.tipoStock,
      }))
    ); */
      return;
    }

    const startIndex = (pageNumber - 1) * pageSize;

    // Using splice to keep the products ordered but push method is faster, change if necessary
    /* Currently its setting each page in the correct position, so it will populate the remaining 
      unfetched previous pages with undefined elements thus using the populatedPages variable as a control
      variable */
    this.products.splice(
      startIndex,
      0,
      ...productsIn.map((product) => ({
        ref: product.strCodigo,
        design: product.strDescricao,
        strAbreviatura: product.strAbreviatura,
        ftlVolume: product.ftlVolume,
        tipoStock: product.tipoStock,
        documento: product.documento,
        refAndDesign: `${product.strCodigo.trim()} - ${product.strDescricao.trim()}${
          product.strAbreviatura && product.strAbreviatura != null
            ? " - [" + product.strAbreviatura.trim() + "]"
            : ""
        }`,
      }))
    );
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  // @Action
  // [Actions.LOAD_PRODUCTS](query: string) {
  //   ApiService.setHeader();
  //   let queryParams = '?pageNumber=1&bitInactivo=false&pageSize=100';
  //   if(query) {
  //     queryParams += `${query}`;
  //   }
  //     ApiService.get("artigos", queryParams)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_PRODUCTS, data.data)
  //         return data.data;
  //
  //       })
  //       .catch(({ data }) => {
  //         console.log(data)
  //       });
  //
  // }

  // TODO: This should be adapted to bulk all at once or by page, with options to control: pageSize, pageStart, route, fullMap, timeout
  @Action
  [Actions.LOAD_PRODUCTS](query: string) {
    ApiService.setHeader();

    // Control page size here
    const pageSize = 2000;

    let pageNumber = 1;
    let totalPages = 0;
    let totalProducts = 0;

    // Control variable to know when all pages were fetched
    let populatedPages = 0;

    // Restart products
    this.context.commit(Mutations.SET_PRODUCTS, []);

    const fetchData = () => {
      console.log("Fetching page: " + pageNumber);

      ApiService.get(
        "artigos/getArtigosBulk",
        `?pageNumber=${pageNumber}&bitInactivo=false&isBulk=true&pageSize=${pageSize}${
          query ? "&" + query : ""
        }`
      )
        .then(async ({ data }) => {
          this.context.commit(Mutations.SET_PRODUCTS_BY_PAGE, {
            productsIn: data.Data,
            pageNumber,
            pageSize,
          });

          populatedPages++;

          console.log(
            "Page: " +
              (totalProducts == 0 ? 1 : data.TotalPages) +
              " fetched. | Pages populated: " +
              populatedPages
          );

          if (populatedPages == totalPages) {
            console.log(`Done! All ${totalPages} pages fetched in a total of ${this.getProducts.length} products.`);
          }

          if (totalProducts == 0) totalProducts = data.TotalPages;

          if (totalPages == 0) {
            // Determine total pages required to fetch all products
            totalPages = Math.ceil(totalProducts / pageSize);

            if (totalPages < 1 || pageNumber < 1) return;

            // Queue the rest of the requests
            for (pageNumber++; pageNumber <= totalPages; pageNumber++) {
              // Delay in ms between requests if needed
              // await new Promise((resolve) => setTimeout(resolve, 500));
              fetchData();
            }
          }
        })
        .catch(({ data }) => {
          console.log(data);
        });
    };
    fetchData();
  }

}
